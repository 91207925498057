import { h } from 'preact';
// @ts-ignore
import style from './home';

const Home = () => (
    <div class={style.centered}>
        <h1>Project Template</h1>
    </div>
);

export default Home;
